import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: solid 1px #000;
  ${({ variantId }) => `
    &:before {
        content: "${variantId}";
        position:absolute;
        top: 10px;
        right: 10px;
        background: gray;
        color: black;
        font-size: 12px;
        padding: 5px 10px 3px;
        font-weight: bold;
        border-radius: 10px;
        z-index: 9999;
        border: solid 1px #000;
    }`}
`;
