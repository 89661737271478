import React, { useContext } from 'react';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './VariantContainer.styles';

const VariantContainer = ({ variantId = 'DEFAULT', children }) => {
  const { pageVariantId = 'DEFAULT' } = useContext(FunnelContext);

  if (Builder.isEditing && Builder.previewingModel === 'banners') {
    return <S.Container variantId={variantId}>{children}</S.Container>;
  }

  return variantId === pageVariantId ? <>{children}</> : null;
};

export default VariantContainer;
